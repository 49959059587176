import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import randomInteger from '../../constants/randomInteger'
import useWindowSize from '../../hooks/useWindowSize'
import useDimensions from '../../hooks/useDimensions'
import Styled from '../style'

const delay = 0.2

const Image = ({ image, title, id, index, onLoad }) => {
    const [loaded, setLoaded] = useState(false)
    const windowSize = useWindowSize()
    const [ref, dimensions] = useDimensions()

    const identifier = `item-${id}`

    useEffect(() => {
        if (windowSize && dimensions && !loaded) {
            gsap.set(document.getElementById(identifier), {
                x:
                    windowSize.width / 2 -
                    dimensions.left -
                    dimensions.width / 2,
                y:
                    windowSize.height / 2 -
                    dimensions.top -
                    dimensions.height / 2,
                scale: 0.8,
                rotation: randomInteger(-15, 15),
                opacity: 1,
                filter: 'contrast(0.95) brightness(0.95)',
                delay: delay * (index + 1),
                onComplete: () => {
                    setLoaded(true)
                    onLoad()
                },
            })
        }
    }, [windowSize, dimensions])

    return (
        <Styled.Image
            id={identifier}
            className="item-img-wrap js-img-wrap js-img aspect-ratio"
            data-index={index}
            ref={ref}
            style={{ opacity: 0 }}
        >
            <GatsbyImage
                className="js-item-img item-img"
                image={getImage(image)}
                alt={title}
                loading="eager"
            />
        </Styled.Image>
    )
}

export default Image
