import React, { useState, useEffect } from 'react'
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import Item from './item'
import Container from '../container'
import Progress from '../progress'
import Counter from '../counter'
import ArrowLeft from '../../images/arrow-left.inline.svg'
import ArrowRight from '../../images/arrow-right.inline.svg'
import useWindowSize from '../../hooks/useWindowSize'
import Styled from '../style'

gsap.registerPlugin(CustomEase)

CustomEase.create('in-out-smooth', 'M0,0 C0.8,0 0.2,1 1,1')

const intro = gsap.timeline({ paused: true }).addLabel('startAnimation')

function slide(slides, scroll) {
    const scrollLast = Math.abs(scroll.state.last)
    let progress = 0
    const slideContainer = document.getElementById('slider')

    slides.forEach((item, index) => {
        const { bounds, button } = item
        const inView =
            scrollLast + window.innerWidth >= bounds.left &&
            scrollLast < bounds.right

        if (inView) {
            const min = bounds.left - window.innerWidth
            const max = bounds.right
            const percentage = ((scrollLast - min) * 100) / (max - min)
            const newMin = -(window.innerWidth / 7)
            const newMax = 0
            item.x = ((percentage - 0) / (100 - 0)) * (newMax - newMin) + newMin
            item.img.style.transform = `translate3d(${item.x}px, 0, 0) scale(1.75)`
            if (slideContainer.classList.contains('loaded')) {
                gsap.to(button, {
                    duration: 0.8,
                    ease: 'expo.out',
                    y: 0,
                    delay: 0.15,
                })
            }
        } else {
            gsap.to(button, {
                duration: 0.8,
                ease: 'expo.in',
                y: -slides[0].bounds.height,
            })
        }

        const min = 0
        const max = -scroll.state.bounds.width + window.innerWidth
        progress = ((scroll.state.last - min) * 100) / (max - min) / 100

        document.querySelector(
            '.js-progress'
        ).style.transform = `scaleX(${progress})`
    })
}

const animateIntro = (slides, scroll) => {
    function slideThis() {
        slide(slides, scroll)
    }
    gsap.ticker.add(slideThis)

    const headings = document.querySelectorAll('.js-heading')
    const buttons = document.querySelectorAll('.js-button')
    const leftLimit = slides[0].bounds.x
    intro
        .set(headings, { y: -slides[0].bounds.height, rotation: -5 })
        .set(buttons, { y: -slides[0].bounds.height * 0.85 })
        .set(document.querySelectorAll('.js-content'), { autoAlpha: 1 })
        .to(
            document.querySelector('.js-counter'),
            { duration: 1, ease: 'in-out-smooth', autoAlpha: 0 },
            'startAnimation'
        )

    for (const [pos, item] of slides.entries()) {
        intro.to(
            document.querySelector('.js-img-wrap[data-index="' + pos + '"]'),
            {
                duration: 1,
                ease: 'circ',
                x: leftLimit - item.bounds.x,
                y: 0,
                rotation: 0,
                scale: 1,
            },
            'startAnimation+=0.1'
        )
    }
    intro
        .to(
            [...document.querySelectorAll('.js-img-wrap')].reverse(),
            {
                duration: 1,
                ease: 'in-out-smooth',
                x: 0,
                filter: 'contrast(0.75) brightness(0.75)',
                stagger: 0.15,
                onComplete: () =>
                    document.getElementById('slider').classList.add('loaded'),
            },
            'startAnimation+=1'
        )
        .to(
            headings,
            {
                duration: 1.6,
                stagger: 0.15,
                ease: 'in-out-smooth',
                y: 0,
                rotation: 0,
            },
            `startAnimation+=${0.175 * slides.length}`
        )
        .to(
            document.querySelector('.js-progress-wrap'),
            {
                duration: 0.85,
                ease: 'in-out-smooth',
                scaleX: 1,
            },
            `startAnimation+=${0.2 * slides.length}`
        )
        .to(document.querySelector('.js-sliderNav'), {
            duration: 0.45,
            ease: 'in-out-smooth',
            autoAlpha: 1,
        })

    intro.play()
}

const Slider = ({ nodes }) => {
    const [scroll, setScroll] = useState(null)
    const [slides, setSlides] = useState([])
    const { width: windowWidth } = useWindowSize()

    const loadScroll = (val) => {
        setScroll(val)
    }

    const loadSlides = (val) => {
        setSlides([...slides, val])
    }

    useEffect(() => {
        if (slides.length === nodes.length) {
            animateIntro(slides, scroll)
            if (scroll) {
                scroll.init()
            }
        }
    }, [slides, nodes, scroll])

    const move = (e) => {
        const val = (e * windowWidth) / 2
        scroll.move(val)
    }

    return (
        <Styled.Slider id="slider">
            <Container updateScroll={(e) => loadScroll(e)}>
                {nodes.length &&
                    nodes.map((item, index) => (
                        <Item
                            {...item}
                            index={index}
                            key={index}
                            onLoad={(e) => loadSlides(e)}
                        />
                    ))}
            </Container>
            <Progress />
            <Counter>{slides.length}</Counter>
            <Styled.Nav className="js-sliderNav">
                <Styled.NavButton onClick={() => move(1)}>
                    <ArrowLeft />
                </Styled.NavButton>
                <Styled.NavButton onClick={() => move(-1)}>
                    <ArrowRight />
                </Styled.NavButton>
            </Styled.Nav>
        </Styled.Slider>
    )
}

export default Slider
