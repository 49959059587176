import React from 'react'
import Layout from '../components/layout'
import Slider from '../components/intro/slider'
import { useStaticQuery, graphql } from 'gatsby'

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        {
            allClientsJson {
                nodes {
                    id
                    image {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    link
                    category
                }
            }
        }
    `)
    const { nodes } = data.allClientsJson

    return (
        <Layout
            description={`The Social Hustle portfolio showcases our expertise in creating custom digital solutions that drive results. Explore our portfolio to see our work and how we've helped businesses succeed.`}
            title="Social Hustle Digital Marketing Portfolio"
            allLink="/"
        >
            <Slider nodes={nodes} />
        </Layout>
    )
}

export default IndexPage
