import React from 'react'
import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'

const S = {
    Counter: styled.div`
        text-align: center;
        position: absolute;
        left: 25%;
        bottom: 0;
        width: 50%;
        font-size: 18vw;
        font-weight: 900;
        display: none;

        ${mediaQueries.sm} {
            display: block;
            font-size: 12vw;
        }
        ${mediaQueries.md} {
            font-size: 4vw;
        }
    `,
}

const Counter = ({ children }) => {
    return (
        <S.Counter className="slider-counter js-counter">{children}</S.Counter>
    )
}

export default Counter
